/* eslint-disable */

import L from 'leaflet';
import 'leaflet.markercluster';
import GestureHandling from "leaflet-gesture-handling";

export default {
	init() {
    if (document.getElementsByClassName('vy_map_container').length > 0) {
      preparMap();
    }
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};

function initMap(mapinfo) {
  L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

  var myLat = mapinfo.datalat;
  var myLng = mapinfo.datalng;
  var myLatLng = { lat: mapinfo.datalat, lng: mapinfo.datalng };

  var map = L.map(mapinfo.id,{
    center:[myLat, myLng],
    gestureHandling: true,
    maxZoom: 22,
    gestureHandlingOptions: {
      text: {
          touch: mapinfo.text_touch,
          scroll: mapinfo.text_scroll,
          scrollMac: mapinfo.text_scrollmac
      }
    },
    zoom:mapinfo.zoom
  })
  L.tileLayer(
    'https://api.mapbox.com/styles/v1/voyou/'+mapinfo.style+'/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoidm95b3UiLCJhIjoiY2o2bnl3bnRvMGQzbjJxbWtvMmZneDFkdSJ9.A9yK4ayih5N4P0GuQU5OFw'
  ).addTo(map);

  var myIcon = L.divIcon({
    className: 'vy_map_icon'
  });

    if (mapinfo.multiple_markers) {
      // MULTIPLE MARKERS
      var markers_object = $(mapinfo.multiple_markers);
      var markers = L.markerClusterGroup({maxClusterRadius:80});
      var markers_latlngs = [];
      var index = 0;
      markers_object.each(function () {

        function ClickMarker(e) {
          var popup = e.target.getPopup();
          var content = popup.getContent();
          e.target.openPopup();
        }

        var marker = L.marker([$(this).data('lat'), $(this).data('lng')],{icon: myIcon});
        markers.addLayer(marker)

        var content_image = '';
        var content_title = '';
        var content_address = '';
        var content_phone = '';
        var content_seemap = mapinfo.text_seemap;
        var tmp_lat = $(this).data('lat')
        var tmp_lng = $(this).data('lng')

        if ($(this).attr('data-title')) {
          content_title = $(this).data('title');
        }
        if ($(this).attr('data-address')) {
          content_address = $(this).data('address');
        }
        if ($(this).attr('data-image')) {
          content_image = $(this).data('image');
        }
        if ($(this).attr('data-phone')) {
          content_phone = $(this).data('phone');
        }

        var content = '<div data-index="'+index+'" class="vy_map_popup_content"><div class="vy_map_popup_content_container">';
        if(content_image != '') { content += '<div class="vy_map_popup_content_image" style="background-image:url('+content_image+')"></div>'; }
        if(content_title != '') { content += '<h4 class="vy_map_popup_content_title">'+content_title+'</h4>'; }
        if(content_address != '') { content += '<p class="vy_map_popup_content_address">'+content_address+'</p>'; }
        if(content_phone != '') { content += '<p class="vy_map_popup_content_phone">'+content_phone+'</p>'; }
        content +='<a target="_blank" href="https://maps.google.com/?daddr=' + tmp_lat + ',' + tmp_lng +'" class="vy_map_popup_content_link uk-button uk-button-primary uk-button-small">' +
        '<span>'+content_seemap+'</span>' +
        '<span class="uk-icon" data-uk-icon="chevron-right"></span>'
        "</a>";
        content += '</div></div>';
        marker.bindPopup(content);
        markers_latlngs.push(marker._latlng);



        marker.on('click', ClickMarker);

        map.addLayer(markers);

        index = index + 1;
      });
      var bounds = new L.LatLngBounds(markers_latlngs).extend();
      map.fitBounds(bounds.pad(0.5));


    }else{
      // SIMPLE MARKER
      var marker = L.marker([myLat, myLng],{icon: myIcon});
      marker.addTo(map).on('click', function() {
        window.open('https://maps.google.com/?daddr=' + myLat + ',' + myLng);
      })
    }
}

window.preparMap = function () {
  $('.vy_map_container').each(function () {
    var datalat = '';
    var datalng = '';
    var id = $(this).attr('id');
    var zoom = 10;
    var style = 'ckzr6nqlf000d14pc8agd4pmn';
    var multiple_markers = false;
    var text_seemap = 'Voir la carte';
    var text_touch = 'Utilisez deux doigts pour déplacer la carte';
    var text_scroll = 'Utilisez ctrl + scroll pour zoomer sur la carte';
    var text_scrollmac = 'Utilisez \u2318 + scroll pour zoomer sur la carte';

    datalat = $(this).data('lat');
    datalng = $(this).data('lng');

    if ($(this).attr('data-pin')) { pin = $(this).data('pin');}
    if ($(this).attr('data-style')) { style = $(this).data('style');}
    if ($(this).attr('data-zoom')) { zoom = parseInt($(this).data('zoom'));}
    if ($(this).attr('data-text-touch')) { text_touch = $(this).data('text-touch');}
    if ($(this).attr('data-text-scroll')) { text_scroll = $(this).data('text-scroll');}
    if ($(this).attr('data-text-scrollmac')) { text_scrollmac = $(this).data('text-scrollmac');}
    if ($(this).attr('data-text-seemap')) { text_seemap = $(this).data('text-seemap');}
    if ($(this).attr('data-multiple-marker')) {multiple_markers = $(this).data('multiple-marker');}
    var mapinfo = { text_touch:text_touch,text_seemap:text_seemap,style:style,text_scroll:text_scroll,text_scrollmac:text_scrollmac,datalat: datalat, datalng: datalng, id: id, zoom: zoom,multiple_markers: multiple_markers };
    initMap(mapinfo);
  });
}
